import { ReactNode, createContext, useContext } from "react";
import { useFilterSignatureCertificate } from "./filter-signature-certificate.hook";

const FilterSignatureCertificateContext = createContext(
  {} as ReturnType<typeof useFilterSignatureCertificate>
);
export function useFilterSignatureCertificateContext() {
  return useContext(FilterSignatureCertificateContext);
}

interface FilterSignatureCertificateProviderProps {
  children: ReactNode;
}
export function FilterSignatureCertificateProvider({
  children,
}: FilterSignatureCertificateProviderProps) {
  return (
    <FilterSignatureCertificateContext.Provider
      value={useFilterSignatureCertificate()}
    >
      {children}
    </FilterSignatureCertificateContext.Provider>
  );
}
