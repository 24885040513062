import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, useForm } from "react-hook-form";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { useQyCreateIssueCertificate } from "@factory/query/issue-certificate.query";
import {
  IssueCertificateFormRule,
  IssueCertificateFormSchema,
} from "@factory/form/form.rule";
import { issueCertificateFormDefault } from "@factory/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export function useNewIssueCertificate() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateIssueCertificate } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;
    // showSuccess(`New IssueCertificate is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addIssueCertificate, isLoading: isCreating } =
    useQyCreateIssueCertificate(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<IssueCertificateFormSchema>({
    defaultValues: issueCertificateFormDefault,
    resolver: zodResolver(IssueCertificateFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: IssueCertificateFormSchema) => {
    const formData = mapCreateIssueCertificate(form);
    addIssueCertificate(formData);
  };
  const handleValidateError = (
    err: FieldErrors<IssueCertificateFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return {
    isCreating,
    formMethod,
    navigate,
    handleSubmit,
    handleValidate,
    handleValidateError,
    addIssueCertificate,
  };
}
