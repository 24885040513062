import classNames from "classnames";
import { SyntheticEvent, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Chip, ChipRemoveEvent } from "primereact/chip";
import { LabelValue } from "@shared/models/label-value.interface";

const defaultFilter = (date = "") => {
  let defaultFilter = {} as Record<string, LabelValue>;

  if (date) {
    defaultFilter.date = {
      label: date,
      value: date,
      payload: {
        show: true,
      },
    };
  }

  return defaultFilter;
};

export function useFilterSignatureCertificate() {
  // THIS IS THE LOCAL DECLARATION
  let [searchParams, setSearchParams] = useSearchParams();
  const [filterEntity, setFilterEntity] = useState<Record<string, LabelValue>>(
    defaultFilter("")
  );

  // ADD/REPLACE DEFAULT FILTER STATE
  const [selectedDate, setSelectedDate] = useState("");

  // THIS IS THE LOCAL FUNCTION
  const getFilterEntityValues = useCallback(() => {
    const transformed = LabelValue.TransformAsRecord(filterEntity);
    return transformed;
  }, [filterEntity]);
  const getFilterCount = () => {
    const values = Object.values(filterEntity).filter((x) => !!x);
    let filterEntityCount = values.length || 0;

    return filterEntityCount;
  };
  const applyFilter = (field: string, data: LabelValue) => {
    const filterVal = {
      ...filterEntity,
      [field]: data,
    } as Record<string, LabelValue>;

    setFilterEntity(filterVal);
  };
  const removeAllFilters = () => {
    const filterVal = {} as Record<string, LabelValue>;
    setSearchParams({});
    setFilterEntity(filterVal);
    setSelectedDate("");
  };
  const removeFilterBy = (filterName: string) => {
    const updatedFilterEntity = {
      ...filterEntity,
      [filterName]: {} as LabelValue,
    };
    setFilterEntity(updatedFilterEntity);

    if (filterName === "date") {
      setSelectedDate("");
    }
  };
  const handleRemoveChip = (e: ChipRemoveEvent) => {
    if (e.value.indexOf(":") < 0) {
      return;
    }

    const filterName = e.value.split(":")[0];
    removeFilterBy(filterName);
  };
  const handleClear = (e: SyntheticEvent) => {
    e.preventDefault();
    removeAllFilters();
  };

  // THIS IS HTML
  const clearFiltersElement = (
    <Button
      label="Clear Filters"
      severity="secondary"
      className="block mt-4"
      outlined
      onClick={handleClear}
    />
  );
  const hasAppliedFilters =
    Object.values(filterEntity).filter((i) => !!i?.payload?.show).length > 0;
  const filterChipsElement = hasAppliedFilters ? (
    <section>
      <small>Applied Filters:</small>
      <div className="flex">
        {Object.keys(filterEntity).map((i) => {
          if (
            filterEntity[i] === ({} as LabelValue) ||
            filterEntity[i]?.payload?.show === false
          ) {
            return null;
          }

          return (
            <Chip
              key={i}
              label={`${i}: ${filterEntity[i].label}`}
              removable
              onRemove={handleRemoveChip}
            />
          );
        })}
      </div>
    </section>
  ) : null;

  // ADD / REPLACE FILTER ELEMENT
  const dateFilterElement = (
    <span className={classNames("flex flex-col-reverse gap-1")}>
      <InputText
        value={selectedDate}
        type="date"
        id="date"
        onInput={(e: any) => {
          setSelectedDate(e.target.value);
          applyFilter("date", e.target.value);
        }}
      />

      <label className="text-xs text-gray-500" htmlFor="date">
        Date
      </label>
    </span>
  );

  return {
    dateFilterElement,
    filterEntity,
    clearFiltersElement,
    filterChipsElement,
    removeAllFilters,
    getFilterCount,
    getFilterEntityValues,
  };
}
