import { GetInhabitantModel } from "@factory/model/query-model-inhabitant";
import CertificatePage from "../certificate-page/certificate-page";
import "./barangay-clearance.scss";
import PrintBarangayClearance from "../print-barangay-clearance/print-barangay-clearance";

/* eslint-disable-next-line */
export interface BarangayClearanceProps {}

export function BarangayClearance(
  selectedInhabitant: Partial<GetInhabitantModel> | null
) {
  return (
    <CertificatePage
      title="Barangay Clearance"
      documentTitle=""
      printComponent={
        <PrintBarangayClearance selectedInhabitant={selectedInhabitant} />
      }
    />
  );
}
export default BarangayClearance;
