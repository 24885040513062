import { useRef, useState } from "react";
import "./add-certificate-signature.scss";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import ReactSignatureCanvas from "react-signature-canvas";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { SignatureCertificateFormSchema } from "@factory/form/form.rule";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { MessageResponseDto } from "@api/api";
import { base64ToFile } from "@core/utility/convert-base64-to-file";
import { useNewSignatureCertificate } from "@domain/signature-certificate/new-signature-certificate/new-signature-certificate.hook";

export interface AddCertificateSignatureProps {}

export function AddCertificateSignature() {
  const { showWarning, showError } = useNotificationContext();
  const { mapCreateSignatureCertificate } = useFormApiMapper();
  const [signatureImage, setSignatureImage] = useState("");
  const { addSignatureCertificate, isCreating } = useNewSignatureCertificate();
  const navigate = useNavigate();
  const sigCanvas: any = useRef();
  const location = useLocation();
  const fromRoute = location.state?.from || "/certificate";

  const handleClearCanvas = () => {
    sigCanvas.current.clear();
  };

  const handleSubmitSignature = () => {
    if (sigCanvas.current?.isEmpty()) {
      showWarning("Signature Field Empty");
      return;
    }

    const signatureImg = sigCanvas.current?.getCanvas().toDataURL("image/png");
    setSignatureImage(signatureImg);
    const signatureFile = base64ToFile(signatureImg, "signature.png");

    const formData: SignatureCertificateFormSchema = {
      name: fromRoute,
      photo: signatureFile,
    };

    const signatureData = mapCreateSignatureCertificate(formData);

    addSignatureCertificate(signatureData, {
      onSuccess: (response: MessageResponseDto) => {
        const data = response.data as any;
        const signatureCertificateCode: string = data.code;

        navigate(fromRoute, {
          state: {
            signatureCertificateCode,
            selectedInhabitant: location.state?.selectedInhabitant,
          },
        });
      },
      onError: (error) => {
        showError(`Failed to save signature: ${error.message}`);
      },
    });
  };

  return (
    <div id="AddSignature" className="add-signature flex justify-center">
      <div className="flex flex-col">
        <ReactSignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{
            width: 900,
            height: 600,
            className: "sigCanvas",
            style: { cursor: "crosshair" },
          }}
          minWidth={3}
          maxWidth={5}
        />
        <div className="flex gap-2 mt-5">
          <Button
            label="Clear"
            severity="secondary"
            onClick={handleClearCanvas}
          />
          <Button
            label="Submit"
            disabled={isCreating}
            severity="success"
            onClick={handleSubmitSignature}
          />
        </div>
      </div>
    </div>
  );
}

export default AddCertificateSignature;
