import './form-issue-certificate.scss';
import { useFormContext } from "react-hook-form";
import { IssueCertificateFormSchema } from "@factory/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";

type InputFormSchema = IssueCertificateFormSchema;
export function FormIssueCertificate() {
  const { control } = useFormContext<IssueCertificateFormSchema>();
  return (
    <div id="IssueCertificate" className="issue-certificate">
      <InputControl<InputFormSchema>
        control={control}
        name="inhabitant"
        label="Inhabitant"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter inhabitant"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="type"
        label="Type"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter type"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="issueDate"
        label="Issue Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter issueDate"
      />
    </div>
  );
}

export default FormIssueCertificate;