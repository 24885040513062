import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetInhabitantModel } from "@factory/model/query-model-inhabitant";
import { inhabitantFormDefault } from "./form.default";
import { InhabitantFormSchema } from "./form.rule";

export const useFormDefaultInhabitant = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetInhabitantModel | undefined) => {
    return !cachedValue
      ? inhabitantFormDefault
      : ({
          householdCode: cachedValue.household_code,
          firstName: cachedValue.first_name,
          lastName: cachedValue.last_name,
          middleName: cachedValue.middle_name,
          avatar: cachedValue.avatar,
          role: cachedValue.role,
          gender: cachedValue.gender,
          civilStatus: cachedValue.civil_status,
          birthday: formatDate(cachedValue.birthday) as any,
          email: cachedValue.email,
          mobile: cachedValue.mobile,
          phone: cachedValue.phone,
          isVerified: Boolean(cachedValue.is_verified),
          isDeceased: Boolean(cachedValue.is_deceased),
          isVoter: Boolean(cachedValue.is_voter),
          isRepresentative: Boolean(cachedValue.is_representative),
          note: cachedValue.note,
          occupation: cachedValue.occupation,
          occupationPlace: cachedValue.occupation_place,
          isSchooling: Boolean(cachedValue.is_schooling),
          gradeLevel: cachedValue.grade_level,
          placeOfRegistration: cachedValue.place_of_registration,
          bloodType: cachedValue.blood_type,
          healthRemarks: cachedValue.health_remarks,
          isPregnant: Boolean(cachedValue.is_pregnant),
          expectedLabourDate: formatDate(
            cachedValue.expected_labour_date
          ) as any,
          isSingleParent: Boolean(cachedValue.is_single_parent),
          streetName: cachedValue.street_name,
          subdivision: cachedValue.subdivision,
          zone: cachedValue.zone,
          sitio: cachedValue.sitio,
          purok: cachedValue.purok,
          barangay: cachedValue.barangay,
          municipality: cachedValue.municipality,
          province: cachedValue.province,
          region: cachedValue.region,
          country: cachedValue.country,
          longitude: cachedValue.longitude,
          latitude: cachedValue.latitude,
        } as InhabitantFormSchema);
  };

  return {
    getDefault,
  };
};
