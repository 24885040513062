import {
  MessageResponseDto,
  DashboardApiFp,
  DashboardControllerAgeRange200Response,
  DashboardDataDto,
} from "@api/api";
import { apiConfig } from "@factory/query/config.query";
import { QueryKey } from "@factory/query/query-key.enum";

export type GetResponseDashboardModel = DashboardControllerAgeRange200Response;
export type GetDashboardModel = DashboardDataDto;
export type MutateResponseDashboardModel = MessageResponseDto;

export const apiDashboardFp = DashboardApiFp(apiConfig);
export const getAgeDashboardController =
  apiDashboardFp.dashboardControllerAgeRange;
export const getVotersDashboardController =
  apiDashboardFp.dashboardControllerVoters;
export const getGenderDashboardController =
  apiDashboardFp.dashboardControllerGenders;
//new added
export const getHouseholdsDashboardController =
  apiDashboardFp.dashboardControllerHouseholds;
export const getInhabitantsDashboardController =
  apiDashboardFp.dashboardControllerInhabitants;
export const getDeceasedDashboardController =
  apiDashboardFp.dashboardControllerDeceased;
export const getInhabitantPurokDashboardController =
  apiDashboardFp.dashboardControllerInhabitantsPerPurok;
export const getHouseholdPurokDashboardController =
  apiDashboardFp.dashboardControllerHouseholdPerPurok;
export const getCertificateByTypesController =
  apiDashboardFp.dashboardControllerCertificatesByType;

export const QueryKeyDashboardAge = QueryKey.DashboardAge;
export const QueryKeyDashboardGender = QueryKey.DashboardGender;
export const QueryKeyDashboardVoter = QueryKey.DashboardVoter;
//new
export const QueryKeyDashboardHousehold = QueryKey.DashboardHousehold;
export const QueryKeyDashboardInhabitant = QueryKey.DashboardInhabitant;
export const QueryKeyDashboardDeceased = QueryKey.DashboardDeceased;
export const QueryKeyDashboardInhabitantPurok =
  QueryKey.DashboardInhabitantPurok;
export const QueryKeyDashboardHouseholdPurok = QueryKey.DashboardHouseholdPurok;
export const QueryKeyDashboardCertificateByType =
  QueryKey.DashboardCertificateByType;
