import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  SignatureCertificateFormRule,
  SignatureCertificateFormSchema,
} from "@factory/form/form.rule";
import { useFormDefaultSignatureCertificate } from "@factory/form/form-default-signature-certificate.hook";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { ActionEnum } from "@factory/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateSignatureCertificate,
  useQyGetSignatureCertificateById,
  useQyDeleteSignatureCertificate,
} from "@factory/query/signature-certificate.query";
import { GetResponseSignatureCertificateModel } from "@factory/model/query-model-signature-certificate";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { viewAsset } from "@factory/query/google-cloud-storage.query";

export function useEditSignatureCertificate() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultSignatureCertificate();
  const navigate = useNavigate();
  const { mapUpdateSignatureCertificate, mapDeleteSignatureCertificate } =
    useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { signatureCertificateId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID SignatureCertificate
  const handleGetApiSuccess = async (
    data: GetResponseSignatureCertificateModel
  ) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];

      if (responseData?.signature_data) {
        const assetFile = await viewAsset(responseData?.signature_data);
        const blobFile = URL.createObjectURL(assetFile);
        setValue("photo", blobFile);
      }

      setValue("name", responseData?.name || "");
      setValue("signatureData", responseData?.signature_data || "");
    }
  };
  const {
    data: signatureCertificateResponse,
    isLoading,
    isError: signatureCertificateError,
  } = useQyGetSignatureCertificateById(
    signatureCertificateId || "",
    handleGetApiSuccess
  );
  const signatureCertificateData = signatureCertificateResponse?.data?.[0];

  // API UPDATE SignatureCertificate
  const handleUpdateApiSuccess = () => {
    showSuccess("SignatureCertificate updated successfully");
  };
  const { mutate: updateSignatureCertificate, isLoading: isEditLoading } =
    useQyUpdateSignatureCertificate(handleUpdateApiSuccess);

  // API DELETE SignatureCertificate
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("SignatureCertificate deleted successfully");
  };
  const { mutate: deleteSignatureCertificate } =
    useQyDeleteSignatureCertificate(handleDeleteApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<SignatureCertificateFormSchema>({
    defaultValues: getDefault(signatureCertificateData),
    resolver: zodResolver(SignatureCertificateFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: SignatureCertificateFormSchema) => {
    if (!signatureCertificateData || !signatureCertificateData.code) {
      throw new Error("No signatureCertificate data");
    }

    const formData = mapUpdateSignatureCertificate(
      form,
      signatureCertificateData.code
    );
    updateSignatureCertificate(formData);
  };
  const handleValidateError = (
    err: FieldErrors<SignatureCertificateFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!signatureCertificateData || !signatureCertificateData.code) {
      throw new Error("No signatureCertificate data found");
    }

    const form = mapDeleteSignatureCertificate(signatureCertificateData.code);
    deleteSignatureCertificate(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    signatureCertificateId,
    signatureCertificateData,
    isLoading,
    signatureCertificateError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
