import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  SignatureFormRule,
  SignatureFormSchema,
} from "@factory/form/form.rule";
import { useFormDefaultSignature } from "@factory/form/form-default-signature.hook";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { ActionEnum } from "@factory/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateSignature,
  useQyGetSignatureById,
  useQyDeleteSignature,
} from "@factory/query/signature.query";
import { GetResponseSignatureModel } from "@factory/model/query-model-signature";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import {
  useQyGetGoogleCloudAssetPreview,
  viewAsset,
} from "@factory/query/google-cloud-storage.query";

export function useEditSignature() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultSignature();
  const navigate = useNavigate();
  const { mapUpdateSignature, mapDeleteSignature } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { signatureId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID Signature
  const handleGetApiSuccess = async (data: GetResponseSignatureModel) => {
    if (!data || data.data?.length === 0) {
      return;
    }
    const responseData = data.data?.[0];

    if (responseData?.signature_data) {
      const assetFile = await viewAsset(responseData?.signature_data);
      const blobFile = URL.createObjectURL(assetFile);
      setValue("photo", blobFile);
    }
    setValue("attendanceCode", responseData?.attendance_code || "");
    setValue("signatureData", responseData?.signature_data || "");
  };

  const {
    data: signatureResponse,
    isLoading,
    isError: signatureError,
  } = useQyGetSignatureById(signatureId || "", handleGetApiSuccess);
  const signatureData = signatureResponse?.data?.[0];

  // API VIEW ASSET
  // const { mutate: viewAsset } = useQyGetGoogleCloudAssetPreview();

  // API UPDATE Signature
  const handleUpdateApiSuccess = () => {
    showSuccess("Signature updated successfully");
  };
  const { mutate: updateSignature, isLoading: isEditLoading } =
    useQyUpdateSignature(handleUpdateApiSuccess);

  // API DELETE Signature
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Signature deleted successfully");
  };
  const { mutate: deleteSignature } = useQyDeleteSignature(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<SignatureFormSchema>({
    defaultValues: getDefault(signatureData),
    resolver: zodResolver(SignatureFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: SignatureFormSchema) => {
    if (!signatureData || !signatureData.code) {
      throw new Error("No signature data");
    }

    const formData = mapUpdateSignature(form, signatureData.code);
    updateSignature(formData);
  };
  const handleValidateError = (err: FieldErrors<SignatureFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!signatureData || !signatureData.code) {
      throw new Error("No signature data found");
    }

    const form = mapDeleteSignature(signatureData.code);
    deleteSignature(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    signatureId,
    signatureData,
    isLoading,
    signatureError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
