import { viewAsset } from "@factory/query/google-cloud-storage.query";

export async function getBase64(signatureData: string): Promise<string | null> {
  try {
    const imgBlob = await viewAsset(signatureData);
    const imgURL = URL.createObjectURL(imgBlob);
    const img = new Image();
    img.src = imgURL;

    const base64 = await new Promise<string>((resolve, reject) => {
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL("image/png"));
        } else {
          reject("Failed to get canvas context");
        }
      };
      img.onerror = (error) => reject(error);
    });

    return base64;
  } catch (error) {
    console.error("Error processing signature image:", error);
    return null;
  }
}
