import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetIssueCertificateModel } from "@factory/model/query-model-issue-certificate";
import { issueCertificateFormDefault } from "./form.default";
import { IssueCertificateFormSchema } from "./form.rule";

export const useFormDefaultIssueCertificate = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetIssueCertificateModel | undefined) => {
    return !cachedValue
      ? issueCertificateFormDefault
     : ({
        inhabitant: cachedValue.inhabitant,
        type: cachedValue.type,
        issueDate: cachedValue.issue_date,
      } as IssueCertificateFormSchema);
  };

  return {
    getDefault,
  };
};
