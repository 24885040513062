import * as z from "zod";

const ACCEPTED_IMAGE_TYPES = [
  "image/jpg",
  "image/png",
  "image/jpeg",
  undefined,
  null,
];

export const HouseholdFormRule = z.object({
  number: z.string().optional(),
  name: z.string().optional(),
  streetName: z.string().optional(),
  subdivision: z.string().optional(),
  zone: z.string().optional(),
  sitio: z.string().optional(),
  purok: z.string().optional(),
  barangay: z.string().optional(),
  municipality: z.string().optional(),
  province: z.string().optional(),
  region: z.string().optional(),
  country: z.string().optional(),
  longitude: z.number().optional(),
  latitude: z.number().optional(),
  avatar: z.string().optional(),
  allowBoarders: z.boolean().optional(),
  hasBoardingPermit: z.boolean().optional(),
  houseMaterial: z.string().optional(),
  rooms: z.number().optional(),
  toilets: z.number().optional(),
  hasBackyardGarden: z.boolean().optional(),
  incomeSources: z.string().optional(),
  pets: z.number().optional(),
  twoWheelVehicle: z.number().optional(),
  threeWheelVehicle: z.number().optional(),
  fourWheelVehicle: z.number().optional(),
  photo: z
    .any()
    .optional()
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
      "Only .jpg, .jpeg, and .png formats are supported."
    ),
});
export const InhabitantFormRule = z.object({
  householdCode: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  middleName: z.string().optional(),
  avatar: z.string().optional(),
  role: z.string().optional(),
  gender: z.string().min(1, "Sex is required"),
  civilStatus: z.string().optional(),
  birthday: z.coerce.date().optional().nullish().or(z.string().max(0)),
  email: z.string().optional(),
  mobile: z.string().optional(),
  phone: z.string().optional(),
  isVerified: z.boolean().optional(),
  isDeceased: z.boolean().optional(),
  isVoter: z.boolean().optional(),
  isRepresentative: z.boolean().optional(),
  note: z.string().optional(),
  occupation: z.string().optional(),
  occupationPlace: z.string().optional(),
  isSchooling: z.boolean().optional(),
  gradeLevel: z.string().optional(),
  placeOfRegistration: z.string().optional(),
  bloodType: z.string().optional(),
  healthRemarks: z.string().optional(),
  isPregnant: z.boolean().optional(),
  expectedLabourDate: z.coerce
    .date()
    .optional()
    .nullish()
    .or(z.string().max(0)),
  isSingleParent: z.boolean().optional(),
  photo: z
    .any()
    .optional()
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
      "Only .jpg, .jpeg, and .png formats are supported."
    ),
  streetName: z.string().optional(),
  subdivision: z.string().optional(),
  zone: z.string().optional(),
  sitio: z.string().optional(),
  purok: z.string().optional(),
  barangay: z.string().optional(),
  municipality: z.string().optional(),
  province: z.string().optional(),
  region: z.string().optional(),
  country: z.string().optional(),
  longitude: z.number().optional(),
  latitude: z.number().optional(),
});
export const MeetingFormRule = z.object({
  code: z.string().optional(),
  title: z.string().optional(),
  information: z.string().optional(),
  date: z.string().optional(),
  time: z.string().optional(),
  location: z.string().optional(),
});
export const AttendanceFormRule = z.object({
  household: z.string().optional(),
  inhabitant: z.string().optional(),
  meeting: z.string().optional(),
  arrivalTime: z.string().optional(),
});
export const PersonAddressFormRule = z.object({
  code: z.string().optional(),
  userCode: z.string().optional(),
  streetName: z.string().optional(),
  subdivision: z.string().optional(),
  zone: z.string().optional(),
  sitio: z.string().optional(),
  purok: z.string().optional(),
  barangay: z.string().optional(),
  municipality: z.string().optional(),
  province: z.string().optional(),
  region: z.string().optional(),
  country: z.string().optional(),
  zip: z.string().optional(),
  longitude: z.number().optional(),
  latitude: z.number().optional(),
});
export const PersonFormRule = z.object({
  role: z.string().optional(),
  firstName: z.string().optional(),
  middleName: z.string().optional(),
  lastName: z.string().optional(),
  username: z.string().optional(),
  email: z.string().optional(),
  password: z.string().optional(),
  isVerified: z.boolean().optional(),
  avatar: z.string().optional(),
  activationCode: z.string().optional(),
  gender: z.string().optional(),
  birthday: z.string().optional(),
  isBlocked: z.boolean().optional(),
  mobileNumber: z.string().optional(),
  fatherName: z.string().optional(),
  motherName: z.string().optional(),
  emergencyContact_1Number: z.string().optional(),
  emergencyContact_1Name: z.string().optional(),
  emergencyContact_1Relationship: z.string().optional(),
  emergencyContact_2Number: z.string().optional(),
  emergencyContact_2Name: z.string().optional(),
  emergencyContact_2Relationship: z.string().optional(),
  sss: z.string().optional(),
  philhealth: z.string().optional(),
  pagibig: z.string().optional(),
  tin: z.string().optional(),
  medicalCondition: z.string().optional(),
  bloodType: z.string().optional(),
  elementary: z.string().optional(),
  highSchool: z.string().optional(),
  college: z.string().optional(),
  postCollege: z.string().optional(),
  undergraduate: z.string().optional(),
  curriculumVitae: z.string().optional(),
  supportingDoc: z.string().optional(),
  intentLetter: z.string().optional(),
  applicationLetter: z.string().optional(),
  photo: z
    .any()
    .optional()
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
      "Only .jpg, .jpeg, and .png formats are supported."
    ),
  address: PersonAddressFormRule.optional(),
});
export const RoleFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const SignatureFormRule = z.object({
  attendanceCode: z.string().optional(),
  signatureData: z.string().optional(),
  photo: z
    .any()
    .optional()
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
      "Only .jpg, .jpeg, and .png formats are supported."
    ),
});
export const SignatureCertificateFormRule = z.object({
  name: z.string().optional(),
  signatureData: z.string().optional(),
  photo: z
    .any()
    .optional()
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
      "Only .jpg, .jpeg, and .png formats are supported."
    ),
});
export const IssueCertificateFormRule = z.object({
  inhabitant: z.string().optional(),
  type: z.string().optional(),
  issueDate: z.string().optional(),
});
// RULE ADD
export const LoginRule = z.object({
  username: z.string().min(1, "Email/Username is required"),
  password: z.string().min(1, { message: "Password is required" }),
});

export type LoginFormSchema = z.infer<typeof LoginRule>;
export type HouseholdFormSchema = z.infer<typeof HouseholdFormRule>;
export type MeetingFormSchema = z.infer<typeof MeetingFormRule>;
export type AttendanceFormSchema = z.infer<typeof AttendanceFormRule>;
export type InhabitantFormSchema = z.infer<typeof InhabitantFormRule>;
export type PersonFormSchema = z.infer<typeof PersonFormRule>;
export type PersonAddressFormSchema = z.infer<typeof PersonAddressFormRule>;
export type RoleFormSchema = z.infer<typeof RoleFormRule>;
export type SignatureFormSchema = z.infer<typeof SignatureFormRule>;
export type SignatureCertificateFormSchema = z.infer<
  typeof SignatureCertificateFormRule
>;
export type IssueCertificateFormSchema = z.infer<
  typeof IssueCertificateFormRule
>;
// SCHEMA ADD
