import HeaderContent from "@shared/ui/header-content/header-content";
import { FaChevronLeft } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import React, { useRef, useState, ReactNode } from "react";
import { useReactToPrint } from "react-to-print";
import { useQyGetInhabitant } from "@factory/query/inhabitant.query";
import SearchInput from "@shared/ui/search-input/search-input";
import { MdClear } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { GetInhabitantModel } from "@factory/model/query-model-inhabitant";
import { IssueCertificateFormSchema } from "@factory/form/form.rule";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { useNewIssueCertificate } from "@domain/issue-certificate/new-issue-certificate/new-issue-certificate.hook";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { MessageResponseDto } from "@api/api";

/* eslint-disable-next-line */
export interface CertificatePageProps {
  title: string;
  documentTitle?: string;
  printComponent: ReactNode;
}

export function CertificatePage({
  title,
  documentTitle,
  printComponent,
}: CertificatePageProps) {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateIssueCertificate } = useFormApiMapper();
  const { addIssueCertificate } = useNewIssueCertificate();
  const { showError, showSuccess } = useNotificationContext();
  const componentRef = useRef<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const [selectedInhabitant, setSelectedInhabitant] =
    useState<Partial<GetInhabitantModel> | null>(
      location.state?.selectedInhabitant || {
        first_name: "FIRSTNAME",
        last_name: "LASTNAME",
      }
    );

  // QUERY
  const { data: inhabitantResponse } = useQyGetInhabitant(
    searchTerm || "",
    99,
    0,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    !!searchTerm
  );

  // LOCAL FUNCTION
  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const handleSelectInhabitant = (inhabitant: GetInhabitantModel) => {
    setSelectedInhabitant(inhabitant);
    setSearchTerm("");
  };

  const handleAddSignature = () => {
    navigate(`../certificate-signature`, {
      state: { from: location.pathname, selectedInhabitant },
    });
  };

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: documentTitle || "\u00A0",
    onBeforePrint: () => {
      const currentTitle = document.title;
      document.title = documentTitle || "\u00A0";

      return new Promise<void>((resolve) => {
        resolve();
      }).then(() => {
        document.title = currentTitle;
      });
    },
    pageStyle: `
      @page {
        size: 8.5in 11in;
        margin: 0.5in;
      }
         
      body {
        font-family: Arial, sans-serif;
      }
        
    `,
  });

  const handleAddCertificate = () => {
    if (selectedInhabitant && selectedInhabitant.code) {
      const type = location.pathname.split("/").pop();
      const formData: IssueCertificateFormSchema = {
        inhabitant: selectedInhabitant.code,
        type: type || "",
        issueDate: new Date().toDateString(),
      };

      const issueCertificateData = mapCreateIssueCertificate(formData);

      addIssueCertificate(issueCertificateData, {
        onSuccess: (response: MessageResponseDto) => {
          const data = response.data as any;

          showSuccess("Issued certificate successfully.");
          navigate(`${location.pathname}`);
        },
        onError: (error) => {
          showError(`Failed to save certificate: ${error.message}`);
        },
      });
    }
  };

  // HTML
  const filterElement = (
    <div className="search-section mb-4">
      <SearchInput
        searchTerm={searchTerm}
        onSearch={handleSearch}
        placeholder="Search inhabitant"
        clearIcon={<MdClear />}
        searchIcon={<CiSearch />}
        className="w-full"
      />

      {searchTerm &&
        inhabitantResponse &&
        inhabitantResponse.data &&
        inhabitantResponse?.data.length > 0 && (
          <div className="search-results">
            {inhabitantResponse.data.map((inhabitant) => (
              <div
                key={inhabitant.code}
                onClick={() => handleSelectInhabitant(inhabitant)}
                className="search-result-item"
              >
                {inhabitant.first_name} {inhabitant.last_name}
              </div>
            ))}
          </div>
        )}
    </div>
  );

  const printElement = (
    <div ref={componentRef}>
      {React.cloneElement(printComponent as React.ReactElement, {
        selectedInhabitant,
      })}
    </div>
  );

  return (
    <div
      id="CertificatePage"
      className="certificate-page bg-white p-6 w-full h-full"
    >
      <HeaderContent
        title={title}
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <span className="flex gap-2">
          <Button
            label="Issue certificate"
            onClick={() => handleAddCertificate()}
          />
          <Button
            label="Add signature"
            severity="secondary"
            onClick={handleAddSignature}
          />
          <Button label="Print" onClick={() => handlePrint()} />
        </span>
      </HeaderContent>

      <div className="p-7">
        {filterElement}
        {printElement}
      </div>
    </div>
  );
}

export default CertificatePage;
