export const purokOption = [
  { label: "UNASSIGNED", value: "UNASSIGNED" },
  { label: "Purok 1", value: "PUROK 1" },
  { label: "Purok 2", value: "PUROK 2" },
  { label: "Purok 3", value: "PUROK 3" },
  { label: "Purok 4", value: "PUROK 4" },
  { label: "Purok 5", value: "PUROK 5" },
  { label: "Purok 6", value: "PUROK 6" },
  { label: "Purok 7", value: "PUROK 7" },
];
