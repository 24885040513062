import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  InhabitantFormRule,
  InhabitantFormSchema,
} from "@factory/form/form.rule";
import { useFormDefaultInhabitant } from "@factory/form/form-default-inhabitant.hook";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { ActionEnum } from "@factory/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateInhabitant,
  useQyGetInhabitantById,
  useQyDeleteInhabitant,
} from "@factory/query/inhabitant.query";
import { GetResponseInhabitantModel } from "@factory/model/query-model-inhabitant";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { useQyGetGoogleCloudAssetPreview } from "@factory/query/google-cloud-storage.query";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { useEditInhabitantMenu } from "./edit-inhabitant-menu";

export function useEditInhabitant() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultInhabitant();
  const navigate = useNavigate();
  const { mapUpdateInhabitant, mapDeleteInhabitant } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { inhabitantId } = useParams();
  const actions: ButtonAction[] = [
    { label: "QR Code", type: "more", value: ActionEnum.NewQr },
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];
  const componentRef = useRef<any>(null);
  const handlePrint = useReactToPrint({ contentRef: componentRef });
  const { menu, activeIndex, setActiveIndex } = useEditInhabitantMenu();

  // QUERY DECLARATION
  // API GETBYID Inhabitant
  const handleGetApiSuccess = (data: GetResponseInhabitantModel) => {
    if (!data || data.data?.length === 0) {
      return;
    }

    const responseData = data.data?.[0];

    if (responseData?.avatar) {
      viewAsset(responseData?.avatar, {
        onSuccess(data) {
          const assetDisplay = URL.createObjectURL(data);
          setValue("photo", assetDisplay);
        },
      });
    }

    setValue("householdCode", responseData?.household_code || "");
    setValue("firstName", responseData?.first_name || "");
    setValue("lastName", responseData?.last_name || "");
    setValue("middleName", responseData?.middle_name || "");
    setValue("avatar", responseData?.avatar || "");
    setValue("role", responseData?.role || "");
    setValue("gender", responseData?.gender || "");
    setValue("civilStatus", responseData?.civil_status || "");
    setValue("birthday", formatDate(responseData?.birthday) as any, undefined);
    setValue("email", responseData?.email || "");
    setValue("mobile", responseData?.mobile || "");
    setValue("phone", responseData?.phone || "");
    setValue("isVerified", Boolean(responseData?.is_verified));
    setValue("isDeceased", Boolean(responseData?.is_deceased));
    setValue("isVoter", Boolean(responseData?.is_voter));
    setValue("isRepresentative", Boolean(responseData?.is_representative));
    setValue("note", responseData?.note || "");
    setValue("occupation", responseData?.occupation || "");
    setValue("occupationPlace", responseData?.occupation_place || "");
    setValue("isSchooling", Boolean(responseData?.is_schooling));
    setValue("gradeLevel", responseData?.grade_level || "");
    setValue("placeOfRegistration", responseData?.place_of_registration || "");
    setValue("bloodType", responseData?.blood_type || "");
    setValue("healthRemarks", responseData?.health_remarks || "");
    setValue("isPregnant", Boolean(responseData?.is_pregnant));
    setValue(
      "expectedLabourDate",
      formatDate(responseData?.expected_labour_date) as any,
      undefined
    );
    setValue("isSingleParent", Boolean(responseData?.is_single_parent));
  };
  const {
    data: inhabitantResponse,
    isLoading,
    isError: inhabitantError,
  } = useQyGetInhabitantById(inhabitantId || "", handleGetApiSuccess);
  const inhabitantData = inhabitantResponse?.data?.[0];

  // API VIEW ASSET
  const { mutate: viewAsset } = useQyGetGoogleCloudAssetPreview();

  // API UPDATE Inhabitant
  const handleUpdateApiSuccess = () => {
    showSuccess("Inhabitant updated successfully");
  };
  const { mutate: updateInhabitant, isLoading: isEditLoading } =
    useQyUpdateInhabitant(handleUpdateApiSuccess);

  // API DELETE Inhabitant
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Inhabitant deleted successfully");
  };
  const { mutate: deleteInhabitant } = useQyDeleteInhabitant(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<InhabitantFormSchema>({
    defaultValues: getDefault(inhabitantData),
    resolver: zodResolver(InhabitantFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: InhabitantFormSchema) => {
    if (!inhabitantData || !inhabitantData.code) {
      throw new Error("No inhabitant data");
    }

    const formData = mapUpdateInhabitant(form, inhabitantData.code);
    updateInhabitant(formData);
  };

  const handleValidateError = (err: FieldErrors<InhabitantFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!inhabitantData || !inhabitantData.code) {
      throw new Error("No inhabitant data found");
    }

    const form = mapDeleteInhabitant(inhabitantData.code);
    deleteInhabitant(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;

      case ActionEnum.NewQr:
        setTimeout(() => {
          handlePrint();
        }, 500);
        break;
    }
  };

  return {
    inhabitantId,
    inhabitantData,
    isLoading,
    inhabitantError,
    formMethod,
    actions,
    isEditLoading,
    componentRef,
    menu,
    activeIndex,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
