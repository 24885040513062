import { useState, useRef } from "react";
import "./dashboard-voter.scss";
import { DashboardObject } from "@core/model/dashboard-object";
import { LabelValue } from "@shared/models/label-value.interface";
import { useQyGetVoterDashboard } from "@factory/query/dashboard.query";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import { Chart } from "primereact/chart";
import { useNavigate } from "react-router-dom";
import { handleChartClick } from "@core/utility/handle-chart-click";

export function DashboardVoter() {
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: any[];
  }>({
    labels: [],
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});
  const chartRef = useRef<any>(null);
  const navigate = useNavigate();

  const handleSuccess = (response: any) => {
    const dataObject = response as DashboardObject[];
    const cleanedArray = dataObject.map(
      ({ datasets, labels, ...rest }) => rest
    ) as LabelValue<number>[];

    const bgColor = ["#474850", "#1d29ce"];
    const bgHoverColor = ["#414247", "#141c92"];

    const model = {
      labels: cleanedArray.map((item) => item.label),
      datasets: [
        {
          data: cleanedArray.map((item) => item.value),
          backgroundColor: bgColor,
          hoverBackgroundColor: bgHoverColor,
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
      },
      maintainAspectRatio: false,
      responsive: true,
    };

    setChartData(model);
    setChartOptions(options);
  };

  const { isLoading, isError, error } = useQyGetVoterDashboard(
    true,
    handleSuccess
  );

  const filterValues = {
    VOTER: JSON.stringify({ is_voter: "1" }),
    NOT_VOTER: JSON.stringify({ is_voter: "0" }),
  };

  const handleVoterChartClick = (event: React.MouseEvent) => {
    handleChartClick(
      event,
      chartRef,
      chartData,
      navigate,
      "is_voter",
      filterValues,
      "inhabitant"
    );
  };

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occurred" message={(error as any)?.message} />
    </div>
  );

  const chart = (
    <div className="dashboard-voter-chart cursor-pointer">
      <h5 className="text-gray-500">Voters</h5>
      <div className="chart-container">
        <Chart
          type="pie"
          data={chartData}
          options={chartOptions}
          className="w-full md:w-30rem"
          onClick={handleVoterChartClick}
          ref={chartRef}
        />
      </div>
    </div>
  );

  return (
    <div id="DashboardVoter" className="dashboard-voter">
      {isLoading && displayLoading}
      {isError && !isLoading && displayError}
      {!isLoading && !isError && chart}
    </div>
  );
}

export default DashboardVoter;
