import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  IssueCertificateFormRule,
  IssueCertificateFormSchema,
} from "@factory/form/form.rule";
import { useFormDefaultIssueCertificate } from "@factory/form/form-default-issue-certificate.hook";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { ActionEnum } from "@factory/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateIssueCertificate,
  useQyGetIssueCertificateById,
  useQyDeleteIssueCertificate,
} from "@factory/query/issue-certificate.query";
import { GetResponseIssueCertificateModel } from "@factory/model/query-model-issue-certificate";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditIssueCertificate() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultIssueCertificate();
  const navigate = useNavigate();
  const { mapUpdateIssueCertificate, mapDeleteIssueCertificate } =
    useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { issueCertificateId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID IssueCertificate
  const handleGetApiSuccess = (data: GetResponseIssueCertificateModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("inhabitant", responseData?.inhabitant || "");
      setValue("type", responseData?.type || "");
      setValue("issueDate", responseData?.issue_date || "");
    }
  };
  const {
    data: issueCertificateResponse,
    isLoading,
    isError: issueCertificateError,
  } = useQyGetIssueCertificateById(
    issueCertificateId || "",
    handleGetApiSuccess
  );
  const issueCertificateData = issueCertificateResponse?.data?.[0];

  // API UPDATE IssueCertificate
  const handleUpdateApiSuccess = () => {
    showSuccess("IssueCertificate updated successfully");
  };
  const { mutate: updateIssueCertificate, isLoading: isEditLoading } =
    useQyUpdateIssueCertificate(handleUpdateApiSuccess);

  // API DELETE IssueCertificate
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("IssueCertificate deleted successfully");
  };
  const { mutate: deleteIssueCertificate } = useQyDeleteIssueCertificate(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<IssueCertificateFormSchema>({
    defaultValues: getDefault(issueCertificateData),
    resolver: zodResolver(IssueCertificateFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: IssueCertificateFormSchema) => {
    if (!issueCertificateData || !issueCertificateData.code) {
      throw new Error("No issueCertificate data");
    }

    const formData = mapUpdateIssueCertificate(form, issueCertificateData.code);
    updateIssueCertificate(formData);
  };
  const handleValidateError = (
    err: FieldErrors<IssueCertificateFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!issueCertificateData || !issueCertificateData.code) {
      throw new Error("No issueCertificate data found");
    }

    const form = mapDeleteIssueCertificate(issueCertificateData.code);
    deleteIssueCertificate(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    issueCertificateId,
    issueCertificateData,
    isLoading,
    issueCertificateError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
