import './new-issue-certificate.scss';

import { FormProvider } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import HeaderContent from "@shared/ui/header-content/header-content";
import FormIssueCertificate from "../form-issue-certificate/form-issue-certificate";
import { useNewIssueCertificate } from './new-issue-certificate.hook';

export function NewIssueCertificate() {  
  const {
    isCreating,
    formMethod,
    navigate,
    handleSubmit,
    handleValidate,
    handleValidateError,
  } = useNewIssueCertificate();

  return (
    <div id="IssueCertificate" className="issue-certificate">
      <HeaderContent
        title="New Issue Certificate"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormIssueCertificate />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewIssueCertificate;