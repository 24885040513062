import HeaderContent from "@shared/ui/header-content/header-content";
import DashboardAge from "./dashboard-age/dashboard-age";
import DashboardGender from "./dashboard-gender/dashboard-gender";
import DashboardVoter from "./dashboard-voter/dashboard-voter";
import "./dashboard.scss";
import DashboardHousehold from "./dashboard-household/dashboard-household";
import DashboardInhabitant from "./dashboard-inhabitant/dashboard-inhabitant";
import DashboardNonVoterCard from "./dashboard-non-voter-card/dashboard-non-voter-card";
import DashboardVoterCard from "./dashboard-voter-card/dashboard-voter-card";
import DashboardDeceased from "./dashboard-deceased/dashboard-deceased";
import DashboardInhabitantPurok from "./dashboard-inhabitant-purok/dashboard-inhabitant-purok";
import DashboardHouseholdPurok from "./dashboard-household-purok/dashboard-household-purok";
import { DashboardProvider } from "@core/utility/dashboard-contex";
import DashboardIssueCertificate from "./dashboard-issue-certificate/dashboard-issue-certificate";

// check peackock
export function Dashboard() {
  return (
    <DashboardProvider>
      <div id="Dashboard" className="dashboard">
        <div className="dashboard-title">
          <HeaderContent title="Dashboard" />
        </div>
        <div className="dashboard-card-wrapper">
          <div className="dashboard-card">
            <DashboardHousehold />
          </div>
          <div className="dashboard-card">
            <DashboardInhabitant />
          </div>
          <div className="dashboard-card">
            <DashboardVoterCard />
          </div>
          <div className="dashboard-card">
            <DashboardNonVoterCard />
          </div>
          <div className="dashboard-card">
            <DashboardDeceased />
          </div>
        </div>
        <div className="dashboard-chart-wrapper">
          <div className="dashboard-gender-bar-graph">
            <DashboardGender />
          </div>
          <div className="dashboard-chart-voters">
            <DashboardVoter />
          </div>
          <div className="dashboard-chart-age">
            <DashboardAge />
          </div>
        </div>
        <div className="dashboard-chart-wrapper-household-inhabitant-purok">
          <div className="dashboard-chart-issue-certificate">
            <DashboardIssueCertificate />
          </div>
          <div className="dashboard-chart-inhabitant-purok">
            <DashboardInhabitantPurok />
          </div>
          <div className="dashboard-chart-household-purok">
            <DashboardHouseholdPurok />
          </div>
        </div>
      </div>
    </DashboardProvider>
  );
}

export default Dashboard;
