import { 
    MessageResponseDto, 
    GetIssueCertificateDto, 
    CreateIssueCertificateDto, 
    EditIssueCertificateDto,
    IssueCertificateControllerGet200Response,
    IssueCertificateApiFp,
    DeleteIssueCertificateDto,
} from "@api/api";
import { apiConfig } from "@factory/query/config.query";
import { QueryKey } from "@factory/query/query-key.enum";

export type GetResponseIssueCertificateModel = IssueCertificateControllerGet200Response;
export type GetIssueCertificateModel = GetIssueCertificateDto;
export type MutateResponseIssueCertificateModel = MessageResponseDto;
export type CreateIssueCertificateModel = CreateIssueCertificateDto;
export type UpdateIssueCertificateModel = EditIssueCertificateDto;
export type DeleteIssueCertificateModel = DeleteIssueCertificateDto;

export const apiIssueCertificateFp = IssueCertificateApiFp(apiConfig);
export const getIssueCertificateController = apiIssueCertificateFp.issueCertificateControllerGet;
export const createIssueCertificateController = apiIssueCertificateFp.issueCertificateControllerCreate;
export const updateIssueCertificateController = apiIssueCertificateFp.issueCertificateControllerEdit;
export const deleteIssueCertificateController = apiIssueCertificateFp.issueCertificateControllerDelete;

export const QueryKeyIssueCertificate = QueryKey.IssueCertificate;