import { useLocation, useNavigate } from "react-router-dom";
import { getVisibleMenu } from "@shared/utility/menu-visibility";
import { useState } from "react";

function getMenuIndex(path: string) {
  const locationNames = path.split("/").filter((item) => item !== "");
  const currentLocation = locationNames[locationNames.length - 1];
  if (locationNames.length === 2 && currentLocation !== "scan") {
    return 0;
  }

  return 1;
}

export function useEditInhabitantMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(
    getMenuIndex(location.pathname)
  );

  const initialMenu = [
    {
      label: "Details",
      icon: "pi pi-file",
      command: () => navigate(""),
    },
    {
      label: "Issued Certificates",
      icon: "pi pi-file",
      command: () => navigate(`issue-certificate`),
    },
  ];

  const menu = getVisibleMenu(initialMenu);

  return { menu, activeIndex, setActiveIndex };
}
