import { GetInhabitantModel } from "@factory/model/query-model-inhabitant";
import "./print-barangay-certification.scss";
import CertificateHeader from "@core/component/certificate/certificate-header/certificate-header";
import BarangayOfficial from "@core/component/certificate/barangay-official/barangay-official";
import PunongBarangay from "@core/component/certificate/punong-barangay/punong-barangay";
import AmountPaid from "@core/component/certificate/amount-paid/amount-paid";
import { calculateAge } from "@core/utility/calculate-age/calculate-age";
import FormattedDate from "@core/component/formatted-date/formatted-date";

/* eslint-disable-next-line */
export interface PrintBarangayCertificationProps {
  selectedInhabitant: Partial<GetInhabitantModel> | null;
}

export function PrintBarangayCertification({
  selectedInhabitant,
}: PrintBarangayCertificationProps) {
  const age = selectedInhabitant?.birthday
    ? calculateAge(selectedInhabitant.birthday)
    : "AGE";

  const inhabitantName = selectedInhabitant
    ? `${selectedInhabitant.first_name} ${selectedInhabitant.last_name}`
    : "____________";

  const inhabitantStreetAddress = selectedInhabitant?.street_name || "STREET";
  const inhabitantSex = selectedInhabitant?.gender || "SEX";
  const inhabitantBirthday = selectedInhabitant?.birthday
    ? new Date(selectedInhabitant.birthday).toLocaleDateString()
    : "month/day/year";

  return (
    <div
      id="PrintBarangayCertification"
      className="print-barangay-certification"
    >
      <style type="text/css" media="print">
        {`
      @page { size: A4 portrait; }
      
    #PrintBarangayClearance h2 {
        background: linear-gradient(to right, white 5%, #4caf50 20%, #4caf50 80%, white 95%);
        -webkit-print-color-adjust: exact;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        text-align: center;
      }
    `}
      </style>
      <CertificateHeader />

      <section className="clearance-text">
        <h2 className="title text-xl font-bold mt-7 mb-2 certification-title">
          {Array.from("CERTIFICATION").map((letter, index) => (
            <span key={index} className="letter">
              {letter}
            </span>
          ))}
        </h2>

        <div className="flex gap-2 ">
          <div className="w-1/4 border border-gray-600">
            <div className=" my-7">
              <h4 className="text-center font-bold">
                BARANGAY <br />
                OFFICIALS
              </h4>
            </div>
            <BarangayOfficial />
          </div>
          <div
            className="content w-3/4 border border-gray-600"
            contentEditable
            suppressContentEditableWarning
          >
            <div className="font-bold mt-24 text-start ">
              <h4 className="ml-2">TO WHOM IT MAY CONCERN:</h4>
            </div>
            <div className="mt-6 ml-2">
              <p className="indented-paragraph ml-8">
                THIS IS TO CERTIFY that{" "}
                <strong>{inhabitantName.toLocaleUpperCase()}</strong>, FILIPINO,{" "}
                <span>{inhabitantSex.toLocaleUpperCase()}</span>
                {", "}
                <span>{age} years old,</span>
                and a resident of{" "}
                <span>
                  {inhabitantStreetAddress}, POBLACION II, TAGBILARAN CITY.
                </span>
              </p>
            </div>
            <div className="mt-6 ml-2 p-0">
              <p className="indented-paragraph ml-8">
                This is to certify further that{" "}
                <span>
                  {inhabitantName.toLocaleUpperCase()} is Legitimate Son of
                  MOTHER'S NAME and FATHER'S NAME.{" "}
                </span>{" "}
                who was born on{" "}
                <span>
                  {inhabitantBirthday} in MAP LYING- IN CLINIC INC. NEW CALCETA
                  ST.COGON DISTRICT, TAGBILARAN, BOHOL.
                </span>
              </p>
            </div>
            <div
              className="mt-6 ml-2"
              contentEditable
              suppressContentEditableWarning
            >
              <p className="indented-paragraph ml-8">
                This certification is issued upon the request of the party
                concerned to support with Late Registration of birth at the City
                Civil Registrar's Office, Tagbilaran City.
              </p>
            </div>
            <div
              className="mt-6 ml-2 "
              contentEditable
              suppressContentEditableWarning
            >
              <p className="indented-paragraph">
                Issued this <FormattedDate />, at Barangay Poblacion II,
                Tagbilaran City, Bohol, Philippines.
              </p>
            </div>

            <PunongBarangay marginTop="4rem" />
            <AmountPaid />
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrintBarangayCertification;
