import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetSignatureCertificateModel } from "@factory/model/query-model-signature-certificate";
import { signatureCertificateFormDefault } from "./form.default";
import { SignatureCertificateFormSchema } from "./form.rule";

export const useFormDefaultSignatureCertificate = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetSignatureCertificateModel | undefined) => {
    return !cachedValue
      ? signatureCertificateFormDefault
     : ({
        name: cachedValue.name,
        signatureData: cachedValue.signature_data,
      } as SignatureCertificateFormSchema);
  };

  return {
    getDefault,
  };
};
