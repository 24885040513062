import { Outlet } from "react-router-dom";

import { FilterSignatureProvider } from "./filter-signature/filter-signature.context";
import './signature.scss';

export function Signature() {
  return (
    <div id="Signature" className="signature">
       <FilterSignatureProvider>
        <Outlet />
      </FilterSignatureProvider>
    </div>
  );
}

export default Signature;
