import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, useForm } from "react-hook-form";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { useQyCreateSignature } from "@factory/query/signature.query";
import {
  SignatureFormRule,
  SignatureFormSchema,
} from "@factory/form/form.rule";
import { signatureFormDefault } from "@factory/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export function useNewSignature() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateSignature } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`Added signature successfully.`);
    navigate(`../${data.code}`);
  };
  const { mutate: addSignature, isLoading: isCreating } =
    useQyCreateSignature(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<SignatureFormSchema>({
    defaultValues: signatureFormDefault,
    resolver: zodResolver(SignatureFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: SignatureFormSchema) => {
    const formData = mapCreateSignature(form);
    addSignature(formData);
  };
  const handleValidateError = (err: FieldErrors<SignatureFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return {
    isCreating,
    formMethod,
    navigate,
    handleSubmit,
    handleValidate,
    handleValidateError,
    addSignature,
  };
}
