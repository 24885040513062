import './new-signature-certificate.scss';

import { FormProvider } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import HeaderContent from "@shared/ui/header-content/header-content";
import FormSignatureCertificate from "../form-signature-certificate/form-signature-certificate";
import { useNewSignatureCertificate } from './new-signature-certificate.hook';

export function NewSignatureCertificate() {  
  const {
    isCreating,
    formMethod,
    navigate,
    handleSubmit,
    handleValidate,
    handleValidateError,
  } = useNewSignatureCertificate();

  return (
    <div id="SignatureCertificate" className="signature-certificate">
      <HeaderContent
        title="New Signature Certificate"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormSignatureCertificate />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewSignatureCertificate;