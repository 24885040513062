import {
  MessageResponseDto,
  GetSignatureDto,
  SignatureControllerGet200Response,
  SignatureApiFp,
  DeleteSignatureDto,
  CreateSignatureBaseDto,
  EditSignatureBaseDto,
} from "@api/api";
import { apiConfig } from "@factory/query/config.query";
import { QueryKey } from "@factory/query/query-key.enum";

export type GetResponseSignatureModel = SignatureControllerGet200Response;
export type GetSignatureModel = GetSignatureDto & Partial<{ fileBlob: any }>;
export type MutateResponseSignatureModel = MessageResponseDto;
export type CreateSignatureModel = CreateSignatureBaseDto;
export type UpdateSignatureModel = EditSignatureBaseDto;
export type DeleteSignatureModel = DeleteSignatureDto;

export interface CreateSignatureModelExtension {
  signature: CreateSignatureBaseDto;
  file: File;
}

export interface UpdateSignatureModelExtension {
  signature: EditSignatureBaseDto;
  file: File;
}

export const apiSignatureFp = SignatureApiFp(apiConfig);
export const getSignatureController = apiSignatureFp.signatureControllerGet;
export const createSignatureController =
  apiSignatureFp.signatureControllerCreate;
export const updateSignatureController = apiSignatureFp.signatureControllerEdit;
export const deleteSignatureController =
  apiSignatureFp.signatureControllerDelete;

export const QueryKeySignature = QueryKey.Signature;
