import "./form-signature.scss";
import { useFormContext } from "react-hook-form";
import { SignatureFormSchema } from "@factory/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";

type InputFormSchema = SignatureFormSchema;
export function FormSignature() {
  const { control } = useFormContext<SignatureFormSchema>();
  return (
    <div id="Signature" className="signature">
      <section className="mt-4">
        <ImageControl<InputFormSchema>
          control={control}
          name="photo"
          label="Signature Photo"
          imagePreviewClass="w-32 h-32"
        />
      </section>
      <InputControl<InputFormSchema>
        control={control}
        name="attendanceCode"
        label="Attendance Code"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter attendanceCode"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="signatureData"
        label="Signature Data"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter signatureData"
      />
    </div>
  );
}

export default FormSignature;
