import { Outlet } from "react-router-dom";

import { FilterSignatureCertificateProvider } from "./filter-signature-certificate/filter-signature-certificate.context";
import './signature-certificate.scss';

export function SignatureCertificate() {
  return (
    <div id="SignatureCertificate" className="signature-certificate">
       <FilterSignatureCertificateProvider>
        <Outlet />
      </FilterSignatureCertificateProvider>
    </div>
  );
}

export default SignatureCertificate;
