import { ReactNode, createContext, useContext } from "react";
import { useFilterSignature } from "./filter-signature.hook";

const FilterSignatureContext = createContext(
  {} as ReturnType<typeof useFilterSignature>
);
export function useFilterSignatureContext() {
  return useContext(FilterSignatureContext);
}

interface FilterSignatureProviderProps {
  children: ReactNode;
}
export function FilterSignatureProvider({
  children,
}: FilterSignatureProviderProps) {
  return (
    <FilterSignatureContext.Provider value={useFilterSignature()}>
      {children}
    </FilterSignatureContext.Provider>
  );
}