import { ReactNode, createContext, useContext } from "react";
import { useFilterIssueCertificate } from "./filter-issue-certificate.hook";

const FilterIssueCertificateContext = createContext(
  {} as ReturnType<typeof useFilterIssueCertificate>
);
export function useFilterIssueCertificateContext() {
  return useContext(FilterIssueCertificateContext);
}

interface FilterIssueCertificateProviderProps {
  children: ReactNode;
}
export function FilterIssueCertificateProvider({
  children,
}: FilterIssueCertificateProviderProps) {
  return (
    <FilterIssueCertificateContext.Provider value={useFilterIssueCertificate()}>
      {children}
    </FilterIssueCertificateContext.Provider>
  );
}