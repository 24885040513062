import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, useForm } from "react-hook-form";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { useQyCreateSignatureCertificate } from "@factory/query/signature-certificate.query";
import {
  SignatureCertificateFormRule,
  SignatureCertificateFormSchema,
} from "@factory/form/form.rule";
import { signatureCertificateFormDefault } from "@factory/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export function useNewSignatureCertificate() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateSignatureCertificate } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`Signature added successfully!`);
    navigate(`../${data.code}`);
  };
  const { mutate: addSignatureCertificate, isLoading: isCreating } =
    useQyCreateSignatureCertificate(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<SignatureCertificateFormSchema>({
    defaultValues: signatureCertificateFormDefault,
    resolver: zodResolver(SignatureCertificateFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: SignatureCertificateFormSchema) => {
    const formData = mapCreateSignatureCertificate(form);
    addSignatureCertificate(formData);
  };
  const handleValidateError = (
    err: FieldErrors<SignatureCertificateFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return {
    isCreating,
    formMethod,
    navigate,
    handleSubmit,
    handleValidate,
    handleValidateError,
    addSignatureCertificate,
  };
}
