import "./list-issue-certificate.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import { CiSearch } from "react-icons/ci";
import { MdClear } from "react-icons/md";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import SearchInput from "@shared/ui/search-input/search-input";
import HeaderContent from "@shared/ui/header-content/header-content";
import { useQyGetIssueCertificate } from "@factory/query/issue-certificate.query";
import { GetIssueCertificateModel } from "@factory/model/query-model-issue-certificate";
import { TableSort } from "@shared/models/table-sort";
import { FilterIssueCertificate } from "../filter-issue-certificate/filter-issue-certificate";
import { useFilterIssueCertificateContext } from "../filter-issue-certificate/filter-issue-certificate.context";

export function ListIssueCertificate() {
  // THIS IS THE LOCAL DECLARATION
  const navigate = useNavigate();
  const [rowLimit, setRowLimit] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [first, setFirst] = useState(0);
  const [tableSort, setTableSort] = useState<TableSort>({
    sortField: undefined,
    sortOrder: undefined,
  });
  const [sort, setSort] = useState({});
  const [filterPanel, setFilterPanel] = useState(false);
  const { filterChipsElement, getFilterEntityValues } =
    useFilterIssueCertificateContext();

  // THIS IS THE QUERY DECLARATION
  const {
    data: issueCertificateResponse,
    isLoading,
    isError,
    error,
  } = useQyGetIssueCertificate(
    searchTerm,
    rowLimit,
    pageNumber,
    sort,
    getFilterEntityValues()
  );

  // THIS IS THE LOCAL DECLARATION
  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  const handleTableSort = (e: DataTableStateEvent) => {
    setTableSort({
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    const order = e.sortOrder === 1 ? "ASC" : "DESC";
    setSort({ [e.sortField]: order });
  };
  const navigateToRecord = (item: GetIssueCertificateModel) => {
    navigate(`${item.code}`);
  };
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const offsetValue = event.page * rowLimit;
    setFirst(event.first);
    setPageNumber(offsetValue);
    setRowLimit(event.rows);
  };
  const handleNew = () => {
    navigate(`new`);
  };

  // THIS IS THE LOCAL RENDERS
  const displayLoading = (
    <div className="card-loading">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occured" message={(error as any)?.message} />
    </div>
  );
  const filterElement = (
    <section>
      <div className="flex gap-4 items-center mb-2">
        <SearchInput
          clearIcon={<MdClear />}
          searchIcon={<CiSearch />}
          searchTerm={searchTerm}
          onSearch={handleSearch}
          placeholder="Search items"
          className="w-full block"
        />
        <Button
          label="Filter"
          severity="secondary"
          badgeClassName="p-badge-danger"
          onClick={() => setFilterPanel(true)}
          outlined
        />
      </div>

      {filterChipsElement}
      <Sidebar visible={filterPanel} onHide={() => setFilterPanel(false)}>
        <FilterIssueCertificate />
      </Sidebar>
    </section>
  );
  const grid = (
    <section className="mt-6">
      <h5 className="mb-2">
        Total: <b>{issueCertificateResponse?.count}</b>
      </h5>

      <DataTable
        value={issueCertificateResponse?.data}
        selectionMode="single"
        onSort={handleTableSort}
        sortField={tableSort.sortField}
        sortOrder={tableSort.sortOrder}
      >
        <Column field="household_name" header="Household" sortable></Column>
        <Column field="type" header="Type" sortable></Column>
        <Column field="issue_date" header="Issue Date" sortable></Column>
        <Column field="type_count" header="Count" sortable></Column>
      </DataTable>

      <Paginator
        first={first}
        rows={rowLimit}
        totalRecords={issueCertificateResponse?.count}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      />
    </section>
  );

  return (
    <div id="IssueCertificate" className="issue-certificate">
      <HeaderContent title="Issue Certificate"></HeaderContent>

      <div className="p-7">
        {filterElement}
        {isLoading && displayLoading}
        {isError && !isLoading && displayError}
        {!isLoading && !isError && grid}
      </div>
    </div>
  );
}

export default ListIssueCertificate;
