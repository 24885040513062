import "./formatted-date.scss";

/* eslint-disable-next-line */
export interface FormattedDateProps {}

function getDaySuffix(day: number): string {
  if (day >= 11 && day <= 13) return "th";
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function FormattedDate() {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const daySuffix = getDaySuffix(day);
  const month = currentDate.toLocaleString("en-US", { month: "long" });
  const year = currentDate.getFullYear();

  return (
    <span>
      {day}
      {daySuffix} of {month}, {year}
    </span>
  );
}

export default FormattedDate;
