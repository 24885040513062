import { useState, useRef } from "react";
import "./dashboard-issue-certificate.scss";
import { DashboardObject } from "@core/model/dashboard-object";
import { LabelValue } from "@shared/models/label-value.interface";
import { useQyGetIssueCertificateByType } from "@factory/query/dashboard.query";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import { Chart } from "primereact/chart";
import { useNavigate } from "react-router-dom";

export function DashboardIssueCertificate() {
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: any[];
  }>({
    labels: [],
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});
  const chartRef = useRef<any>(null);
  const navigate = useNavigate();

  const handleSuccess = (response: any) => {
    const dataObject = response as DashboardObject[];
    const cleanedArray = dataObject.map(
      ({ datasets, labels, ...rest }) => rest
    ) as LabelValue<number>[];

    const bgColor = ["#474850", "#1d29ce", "#e0950a", "#ec27ec"];
    const bgHoverColor = ["#414247", "#141c92", "#b4790b", "#911091"];

    const model = {
      labels: cleanedArray.map((item) => item.label),
      datasets: [
        {
          data: cleanedArray.map((item) => item.value),
          backgroundColor: bgColor,
          hoverBackgroundColor: bgHoverColor,
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
      },
      maintainAspectRatio: false,
      responsive: true,
    };

    setChartData(model);
    setChartOptions(options);
  };

  const { isLoading, isError, error } = useQyGetIssueCertificateByType(
    true,
    handleSuccess
  );

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occurred" message={(error as any)?.message} />
    </div>
  );

  const chart = (
    <div className="cursor-pointer">
      <h5 className="text-gray-500">Issued Certificates</h5>
      <div className="flex justify-content-center">
        <Chart
          type="pie"
          data={chartData}
          options={chartOptions}
          className="w-full md:w-30rem"
          // onClick={handleIssueCertificateChartClick}
          ref={chartRef}
        />
      </div>
    </div>
  );

  return (
    <div
      id="DashboardIssueCertificate"
      className="dashboard-issue-certificate w-full"
    >
      {isLoading && displayLoading}
      {isError && !isLoading && displayError}
      {!isLoading && !isError && chart}
    </div>
  );
}

export default DashboardIssueCertificate;
