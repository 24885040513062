import {
  MessageResponseDto,
  GetSignatureCertificateDto,
  SignatureCertificateControllerGet200Response,
  SignatureCertificateApiFp,
  DeleteSignatureCertificateDto,
  EditSignatureCertificateBaseDto,
  CreateSignatureCertificateBaseDto,
} from "@api/api";
import { apiConfig } from "@factory/query/config.query";
import { QueryKey } from "@factory/query/query-key.enum";

export type GetResponseSignatureCertificateModel =
  SignatureCertificateControllerGet200Response;
export type GetSignatureCertificateModel = GetSignatureCertificateDto &
  Partial<{ fileBlob: any }>;
export type MutateResponseSignatureCertificateModel = MessageResponseDto;
export type CreateSignatureCertificateModel = CreateSignatureCertificateBaseDto;
export type UpdateSignatureCertificateModel = EditSignatureCertificateBaseDto;
export type DeleteSignatureCertificateModel = DeleteSignatureCertificateDto;

export interface CreateSignatureCertificateModelExtension {
  signature: CreateSignatureCertificateBaseDto;
  file: File;
}

export interface UpdateSignatureCertificateModelExtension {
  signature: EditSignatureCertificateBaseDto;
  file: File;
}

export const apiSignatureCertificateFp = SignatureCertificateApiFp(apiConfig);
export const getSignatureCertificateController =
  apiSignatureCertificateFp.signatureCertificateControllerGet;
export const createSignatureCertificateController =
  apiSignatureCertificateFp.signatureCertificateControllerCreate;
export const updateSignatureCertificateController =
  apiSignatureCertificateFp.signatureCertificateControllerEdit;
export const deleteSignatureCertificateController =
  apiSignatureCertificateFp.signatureCertificateControllerDelete;

export const QueryKeySignatureCertificate = QueryKey.SignatureCertificate;
