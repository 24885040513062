import './new-signature.scss';

import { FormProvider } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import HeaderContent from "@shared/ui/header-content/header-content";
import FormSignature from "../form-signature/form-signature";
import { useNewSignature } from './new-signature.hook';

export function NewSignature() {  
  const {
    isCreating,
    formMethod,
    navigate,
    handleSubmit,
    handleValidate,
    handleValidateError,
  } = useNewSignature();

  return (
    <div id="Signature" className="signature">
      <HeaderContent
        title="New Signature"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormSignature />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewSignature;