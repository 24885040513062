import "./form-meeting.scss";
import { useFormContext } from "react-hook-form";
import { MeetingFormSchema } from "@factory/form/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";

type InputFormSchema = MeetingFormSchema;
export function FormMeeting() {
  const { control } = useFormContext<MeetingFormSchema>();
  return (
    <div id="Meeting" className="meeting">
      <InputControl<InputFormSchema>
        control={control}
        name="title"
        label="Title"
        className="w-full md:w-1/2"
        containerClassName="pb-2"
        placeholder="Enter title"
      />
      <InputTextareaControl<InputFormSchema>
        control={control}
        name="information"
        label="Information"
        className="w-full md:w-1/2"
        containerClassName="pb-2"
        placeholder="Enter information"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="date"
        label="Date"
        className="w-full md:w-1/2"
        containerClassName="pb-2"
        placeholder="Enter date"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="time"
        label="Time"
        className="w-full md:w-1/2"
        containerClassName="pb-2"
        placeholder="Enter time"
        type="time"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="location"
        label="Location"
        className="w-full md:w-1/2"
        containerClassName="pb-2"
        placeholder="Enter location"
      />
    </div>
  );
}

export default FormMeeting;
