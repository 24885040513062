import { useState } from "react";
import "./punong-barangay.scss";

import {
  GetResponseSignatureCertificateModel,
  GetSignatureCertificateModel,
} from "@factory/model/query-model-signature-certificate";
import { viewAsset } from "@factory/query/google-cloud-storage.query";
import { useLocation } from "react-router-dom";
import { useQyGetSignatureCertificateById } from "@factory/query/signature-certificate.query";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";

interface PunongBarangayProps {
  marginTop?: string;
}

export function PunongBarangay({ marginTop = "8rem" }: PunongBarangayProps) {
  // THIS IS THE LOCAL DECLARATION
  const location = useLocation();
  const [responseData, setResponseData] = useState<
    GetSignatureCertificateModel[]
  >([]);
  const signatureCertificateCode = location.state?.signatureCertificateCode;

  // THIS IS THE QUERY DECLARATION
  const handleGetApiSuccess = async (
    response: GetResponseSignatureCertificateModel
  ) => {
    const _responseData = response.data || [];
    if (_responseData.length > 0) {
      const updatedResponse = await Promise.all(
        _responseData.map(async (element) => {
          const elementFile = await viewAsset(element.signature_data);
          const fileBlob = URL.createObjectURL(elementFile);
          return { ...element, fileBlob };
        })
      );
      setResponseData(updatedResponse);
    }
  };

  const {
    data: signatureCertificateResponse,
    isLoading,
    isError: signatureCertificateError,
  } = useQyGetSignatureCertificateById(
    signatureCertificateCode || "",
    handleGetApiSuccess
  );

  // LOCAL FUNCTIONS
  const dynamicMarginTop =
    responseData.length > 0 && responseData[0].fileBlob ? "0rem" : marginTop;

  // DISPLAY CONTENT
  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );

  const displayError = (
    <div className="card">
      <ErrorSection
        title="Error Loading Data"
        message="Unable to load signature certificate data. Please try again later."
      />
    </div>
  );

  const mainContent = (
    <div
      className="punong-barangay mr-10 justify-items-end"
      style={{ marginTop: dynamicMarginTop }}
    >
      {responseData.length > 0 && responseData[0].fileBlob && (
        <div className="signature-image-container text-center mr-5 -mb-5">
          <img
            src={responseData[0].fileBlob}
            alt="Signature"
            style={{
              width: "150px",
              height: "100px",
              objectFit: "contain",
            }}
          />
        </div>
      )}
      <p
        className="font-bold border-b border-black text-center"
        contentEditable
        suppressContentEditableWarning
      >
        JUNE RAMON F. DUROY
      </p>
      <p className="mr-7">Punong Barangay</p>
    </div>
  );

  return (
    <div>
      {isLoading && displayLoading}
      {signatureCertificateError && !isLoading && displayError}
      {!isLoading && !signatureCertificateError && mainContent}
    </div>
  );
}

export default PunongBarangay;
