import { Outlet } from "react-router-dom";

import { FilterIssueCertificateProvider } from "./filter-issue-certificate/filter-issue-certificate.context";
import './issue-certificate.scss';

export function IssueCertificate() {
  return (
    <div id="IssueCertificate" className="issue-certificate">
       <FilterIssueCertificateProvider>
        <Outlet />
      </FilterIssueCertificateProvider>
    </div>
  );
}

export default IssueCertificate;
