import "./list-attendance.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import { CiSearch } from "react-icons/ci";
import { MdClear } from "react-icons/md";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import SearchInput from "@shared/ui/search-input/search-input";
import HeaderContent from "@shared/ui/header-content/header-content";
import { useQyGetAttendance } from "@factory/query/attendance.query";
import {
  GetAttendanceModel,
  GetResponseAttendanceModel,
} from "@factory/model/query-model-attendance";
import { TableSort } from "@shared/models/table-sort";
import { FilterAttendance } from "../filter-attendance/filter-attendance";
import { useFilterAttendanceContext } from "../filter-attendance/filter-attendance.context";
import { viewAsset } from "@factory/query/google-cloud-storage.query";
import { getBase64 } from "@core/utility/convert-to-base64";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function ListAttendance() {
  // THIS IS THE LOCAL DECLARATION
  const navigate = useNavigate();
  const [rowLimit, setRowLimit] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [first, setFirst] = useState(0);
  const [tableSort, setTableSort] = useState<TableSort>({
    sortField: undefined,
    sortOrder: undefined,
  });
  const [sort, setSort] = useState({});
  const [filterPanel, setFilterPanel] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const { filterChipsElement, getFilterEntityValues } =
    useFilterAttendanceContext();
  const [responseData, setResponseData] = useState<GetAttendanceModel[]>([]);
  const { formatTime } = useDateTimeContext();

  // THIS IS THE QUERY DECLARATION
  const handleGetAttendanceApiSuccess = async (
    response: GetResponseAttendanceModel
  ) => {
    const _responseData = response.data || [];
    if (_responseData.length > 0) {
      const updatedResponse = await Promise.all(
        _responseData.map(async (element) => {
          if (element.signatureData) {
            try {
              const elementFile = await viewAsset(element.signatureData);
              const fileBlob = URL.createObjectURL(elementFile);
              return { ...element, fileBlob };
            } catch (error) {
              console.error("Error fetching signature data:", error);
              return { ...element, fileBlob: null };
            }
          } else {
            return { ...element, fileBlob: null };
          }
        })
      );
      setResponseData(updatedResponse);
    }
  };

  const {
    data: attendanceResponse,
    isLoading,
    isError,
    error,
    refetch,
  } = useQyGetAttendance(
    searchTerm,
    isExporting ? 9999 : rowLimit,
    0,
    sort,
    getFilterEntityValues(),
    undefined,
    undefined,
    undefined,
    true,
    handleGetAttendanceApiSuccess
  );

  // THIS IS THE LOCAL DECLARATION
  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  const handleTableSort = (e: DataTableStateEvent) => {
    setTableSort({
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    const order = e.sortOrder === 1 ? "ASC" : "DESC";
    setSort({ [e.sortField]: order });
  };
  const navigateToRecord = (item: GetAttendanceModel) => {
    navigate(`/inhabitant/${item.inhabitant}`);
  };
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const offsetValue = event.page * rowLimit;
    setFirst(event.first);
    setPageNumber(offsetValue);
    setRowLimit(event.rows);
  };
  const handleScan = () => {
    navigate(`../scan`);
  };

  const handleExportPDF = async () => {
    setIsExporting(true);

    const { data: exportData } = await refetch();

    if (!exportData?.data || exportData.data.length === 0) {
      setIsExporting(false);
      return;
    }

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const titleText = "Attendance Report";
    const titleWidth = doc.getTextWidth(titleText);
    const meetingTitle = exportData.data[0]?.title || "Unknown Meeting Title";

    doc.setFontSize(20);
    doc.text(titleText, (pageWidth - titleWidth) / 2, 20);
    doc.setFontSize(14);
    doc.text(`Meeting: ${meetingTitle}`, 14, 30);
    doc.text(`Total Attendees: ${exportData?.count}`, 14, 36);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 14, 42);

    const headers = [
      "No.",
      "INHABITANT",
      "PUROK",
      "PHONE",
      "ARRIVAL",
      "SIGNATURE",
    ];
    doc.setFontSize(11);
    let startY = 55;
    const headerXPositions = [14, 25, 86, 110, 145, 175];

    headers.forEach((header, i) => {
      doc.text(header, headerXPositions[i], startY);
    });

    const data = await Promise.all(
      exportData?.data.map(async (item, index) => {
        const signatureImageBase64 = item.signatureData
          ? await getBase64(item.signatureData)
          : null;

        return [
          index + 1,
          `${item.first_name?.toLocaleUpperCase()} ${item.last_name?.toLocaleUpperCase()}`,
          item.purok,
          item.phone || "N/A",
          formatTime(item.arrival_time),
          signatureImageBase64,
        ];
      })
    );

    let currentY = startY + 10;
    data.forEach((row, index) => {
      if (currentY > 270) {
        doc.addPage();
        currentY = 20;
      }

      doc.text(String(row[0]), headerXPositions[0], currentY);
      doc.text(String(row[1]), headerXPositions[1], currentY);
      doc.text(String(row[2]), headerXPositions[2], currentY);
      doc.text(String(row[3]), headerXPositions[3], currentY);
      doc.text(String(row[4]), headerXPositions[4], currentY);

      if (typeof row[5] === "string") {
        doc.addImage(row[5], "PNG", headerXPositions[5], currentY - 10, 20, 20);
      }

      doc.line(14, currentY + 2, pageWidth - 14, currentY + 2);

      currentY += 15;
    });

    doc.save(`${meetingTitle}.pdf`);
    setIsExporting(false);
  };

  // THIS IS THE LOCAL RENDERS
  const displayLoading = (
    <div className="card-loading">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occured" message={(error as any)?.message} />
    </div>
  );
  const filterElement = (
    <section>
      <div className="flex gap-4 items-center mb-2">
        <SearchInput
          clearIcon={<MdClear />}
          searchIcon={<CiSearch />}
          searchTerm={searchTerm}
          onSearch={handleSearch}
          placeholder="Search items"
          className="w-full block"
        />
        <Button
          label="Filter"
          severity="secondary"
          badgeClassName="p-badge-danger"
          onClick={() => setFilterPanel(true)}
          outlined
        />
      </div>

      {filterChipsElement}
      <Sidebar visible={filterPanel} onHide={() => setFilterPanel(false)}>
        <FilterAttendance />
      </Sidebar>
    </section>
  );
  const grid = (
    <section className="mt-6">
      <h5 className="mb-2">
        Total: <b>{attendanceResponse?.count}</b>
      </h5>

      <DataTable
        value={responseData}
        selectionMode="single"
        onSelectionChange={(e) => navigateToRecord(e.value)}
        onSort={handleTableSort}
        sortField={tableSort.sortField}
        sortOrder={tableSort.sortOrder}
      >
        <Column field="household_name" header="Household" sortable></Column>
        <Column
          field="inhabitant"
          header="Inhabitant"
          body={(rowData) =>
            `${rowData.first_name.toUpperCase()} ${rowData.last_name.toUpperCase()}`
          }
          sortable
        ></Column>
        <Column field="purok" header="Purok" sortable></Column>
        <Column field="date" header="Meeting Date" sortable></Column>
        <Column
          field="arrival_time"
          header="Arrival Time"
          body={(rowData) => formatTime(rowData.arrival_time)}
          sortable
        ></Column>
        <Column
          field="photo"
          header="Signature"
          body={(rowData) => (
            <img
              src={rowData.fileBlob}
              alt={`signature`}
              style={{
                width: "50px",
                height: "50px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          )}
        ></Column>
      </DataTable>

      <Paginator
        first={first}
        rows={rowLimit}
        totalRecords={attendanceResponse?.count}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      />
    </section>
  );

  return (
    <div id="Attendance" className="attendance">
      <HeaderContent title="Attendance">
        <span className="flex gap-2">
          <Button
            label="Export PDF"
            severity="secondary"
            onClick={handleExportPDF}
          />
          <Button label="Scan" onClick={handleScan} />
        </span>
      </HeaderContent>

      <div className="p-7">
        {filterElement}
        {isLoading && displayLoading}
        {isError && !isLoading && displayError}
        {!isLoading && !isError && grid}
      </div>
    </div>
  );
}

export default ListAttendance;
