import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetSignatureModel } from "@factory/model/query-model-signature";
import { signatureFormDefault } from "./form.default";
import { SignatureFormSchema } from "./form.rule";

export const useFormDefaultSignature = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetSignatureModel | undefined) => {
    return !cachedValue
      ? signatureFormDefault
     : ({
        attendanceCode: cachedValue.attendance_code,
        signatureData: cachedValue.signature_data,
      } as SignatureFormSchema);
  };

  return {
    getDefault,
  };
};
